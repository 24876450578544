import React from "react";
import styled from "styled-components";
import { ColorPalette, MaterialIcon } from "empire-ui";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";

const DescriptionContainer = styled.div`
  text-align: left;
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 5px;
  padding-right: 15px;
  @media (max-width: 767px) {
    margin: auto;
  }
  border-left: 4px solid ${ColorPalette.green[0]};
`;

const TitleCol = styled(Col)`
  /* istanbul ignore next */
  padding-bottom: 5px;
  font-family: MuseoSans700Regular, sans-serif;
  font-size: 1rem;
  line-height: 22px;
  color: ${ColorPalette.grey[0]};
`;

const TextCol = styled(Col)`
  padding-bottom: 5px;
  font-family: MuseoSans500Regular, sans-serif;
  font-size: 0.9rem;
  line-height: 20px;
  color: ${ColorPalette.grey[2]};
`;

export const IconDiv = styled.div`
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  padding: 0 10px 0 10px;
`;

const HelpMessageCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0 10px 0px;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
  font-family: MuseoSans700Regular, sans-serif;

  @media (max-width: 425px) {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
`;
const LightBulb = styled(MaterialIcon)`
  color: ${ColorPalette.darkBlue[0]};
`;
export const InfoPanel = ({ topText, subText }) => {
  return (
    <HelpMessageCardContainer>
      <IconDiv data-testid="helpMessageIcon">
        <LightBulb fontSize="3rem" className="material-icons-outlined">
          lightbulb_outline
        </LightBulb>
      </IconDiv>
      <DescriptionContainer>
        <Row>
          <TitleCol data-testid="helpMessageTopText">{topText}</TitleCol>
        </Row>
        <Row>
          <TextCol data-testid="helpMessageSubText">{subText}</TextCol>
        </Row>
      </DescriptionContainer>
    </HelpMessageCardContainer>
  );
};

const defaultProps = {
  topText: "",
  subText: undefined,
};

InfoPanel.defaultProps = defaultProps;

InfoPanel.propTypes = {
  topText: PropTypes.string,
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
