import API from "services/utils/api";

export const verifyBenefitCard = async ({
  policyNumber,
  divisionNumber,
  certNumber,
}) => {
  const url = "/api/v1/registration/benefit-card-validation";
  const body = {
    group: policyNumber,
    division: divisionNumber,
    certificate_number: certNumber,
  };

  try {
    let response = await API.post(url, body);
    return {
      valid: response.data.validBenefitCard,
      inUse: response.data.patientKeyInUse,
    };
  } catch (err) {
    const { isRateLimited, isIneligible } = translateHttpErrorCodes(err);
    return {
      valid: null,
      inUse: null,
      isRateLimited,
      isIneligible,
    };
  }
};

export const verifyCredentials = async ({ username, password }) => {
  const url = "/api/v1/registration/verify-pmw-user";
  const body = {
    username,
    password,
  };

  try {
    let {
      data: {
        authenticated,
        patient_key,
        patient_key_in_use,
        preferred_email,
      } = {},
    } = await API.post(url, body);
    return {
      authenticated,
      patientKey: patient_key,
      patientKeyInUse: patient_key_in_use,
      preferredEmail: preferred_email,
      isRateLimited: false,
      isError: false,
    };
  } catch (err) {
    const isRateLimited = translateHttpErrorCodes(err).isRateLimited;
    return {
      authenticated: false,
      isRateLimited,
      isError: !isRateLimited,
    };
  }
};

export const verifyBirthDate = async ({
  policyNumber,
  divisionNumber,
  certNumber,
  birthDate,
}) => {
  const url = "/api/v1/registration/birthdate-validation";
  const body = {
    group: policyNumber,
    division: divisionNumber,
    certificate_number: certNumber,
    birthdate: birthDate,
  };

  try {
    let response = await API.post(url, body);
    return {
      valid: response.data.validBirthdate,
    };
  } catch (err) {
    const isRateLimited = translateHttpErrorCodes(err).isRateLimited;
    return {
      valid: null,
      isRateLimited,
    };
  }
};

export const verifyEmail = async (email) => {
  const url = "/api/v1/registration/email-validation";
  const body = {
    email: email,
  };

  try {
    let response = await API.post(url, body);
    return {
      inUse: response.data.in_use,
      isAuth0User: response.data.profile_exists,
    };
  } catch (err) {
    const isRateLimited = translateHttpErrorCodes(err).isRateLimited;
    return {
      inUse: null,
      isAuth0User: null,
      isRateLimited,
    };
  }
};

export const sendMemberSummary = async (email, birthdate, isPortal) => {
  const url = "/api/v1/registration/email-certificate-summary";
  const client = isPortal ? "PMP" : "PMA";
  const body = {
    email,
    birthdate,
    client,
  };

  try {
    let response = await API.post(url, body);
    return {
      isValid: response.data.is_valid,
      isRateLimited: false,
    };
  } catch (err) {
    const isRateLimited = translateHttpErrorCodes(err).isRateLimited;
    return {
      isValid: null,
      isRateLimited,
    };
  }
};

export const retreiveEmail = async ({
  policyNumber,
  divisionNumber,
  certNumber,
  pmwUsername,
  pmwPassword,
  isPmwUser,
}) => {
  const url = "/api/v1/registration/email-member";
  const body =
    isPmwUser.toLowerCase() === "true"
      ? {
          pmw_credentials: {
            username: pmwUsername,
            password: pmwPassword,
          },
        }
      : {
          benefit_card: {
            group: policyNumber,
            division: divisionNumber,
            certificate_number: certNumber,
          },
        };

  try {
    let response = await API.post(url, body);
    return {
      email: response.data,
      isRateLimited: false,
    };
  } catch (err) {
    const isRateLimited = translateHttpErrorCodes(err).isRateLimited;
    return {
      email: null,
      isRateLimited: isRateLimited,
    };
  }
};

export const registerUser = async (userDetails, caslConsent, isPortal) => {
  const url = "/api/v1/registration/register";
  const client = isPortal ? "PMP" : "PMA";
  const preparePayload = () => {
    let payload;
    if (userDetails.isPmwUser === "true") {
      payload = {
        pmw_credentials: {
          username: userDetails.pmwUsername,
          password: userDetails.pmwPassword,
        },
        casl_consent: caslConsent,
        email: userDetails.email,
        client: client,
      };
    } else {
      payload = {
        email: userDetails.email,
        birthdate: userDetails.birthDate,
        benefit_card: {
          group: userDetails.policyNumber,
          division: userDetails.divisionNumber,
          certificate_number: userDetails.certNumber,
        },
        casl_consent: caslConsent,
        client: client,
      };
    }
    return payload;
  };
  let isRegistered = false,
    isIneligible = false,
    isRateLimited = false;
  try {
    let response = await API.post(url, preparePayload(), {
      headers: { "Client-Source": client },
    });
    if (response.status === 200 || response.status === 201) {
      isRegistered = true;
    }
  } catch (err) {
    const errorTypes = translateHttpErrorCodes(err);
    isRateLimited = errorTypes.isRateLimited;
    isIneligible = errorTypes.isIneligible;
  }
  return {
    isRegistered,
    isRateLimited,
    isIneligible,
  };
};

const translateHttpErrorCodes = (error) => {
  let isRateLimited = false,
    isIneligible = false;
  if (error.response) {
    if (error.response.status === 429) isRateLimited = true;
    else if (error.response.status === 403) isIneligible = true;

    console.error(
      `Encountered [${error.response.status}] error when verifying the provided information`
    );
    console.error(error.response.data);
  } else if (error.request) {
    // The client never received a response
    console.error("Failed to get a response from PM API");
  } else {
    // Anything else
    console.error("Unknown error occurred when contacting PM API");
  }
  return { isRateLimited, isIneligible };
};

export const resendActivationEmail = async (
  activationEmail,
  existingUser = false,
  isPortal
) => {
  const client = isPortal ? "PMP" : "PMA";
  const url = "/api/v1/registration/password-reset";
  const body = {
    email: activationEmail,
    existing_user: existingUser,
    client: client,
  };
  let isEmailSent = true,
    isRateLimited = false;
  try {
    await API.post(url, body);
  } catch (error) {
    if (error.response.status === 429) isRateLimited = true;
    isEmailSent = false;
    console.error(
      `Encountered [${error.response.status}] error when verifying the provided information`
    );
    console.error(error.response.data);
  }
  return {
    isEmailSent,
    isRateLimited,
  };
};

export const getAppConfig = async () => {
  const url = "/api/v1/config/mobile-app";

  try {
    let response = await API.get(url);
    return {
      appStoreUrl: response.data.app_store_url,
      playStoreUrl: response.data.android_store_url,
    };
  } catch (err) {
    return {
      appStoreUrl: "",
      playStoreUrl: "",
    };
  }
};
