import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { BlueButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import {
  ControlPropTypes,
  FormFieldPropTypes,
} from "components/RegistrationWizard/PropTypes";
import IsPmwUserForm from "components/Forms/isPmwUserForm/isPmwUserForm";
import { BENEFIT_CARD_PAGE_ID } from "Pages/BenefitCard/BenefitCard";
import { PMW_CREDENTIALS_PAGE_ID } from "Pages/PmwCredentials/PmwCredentials";
import { InfoPanel } from "./InfoPanel";

export const IS_PMW_USER_PAGE_ID = "isPMWUserForm";

export const IsPMWUserView = ({ control, formFields }) => {
  const { values } = useFormikContext();
  const { t } = useTranslation();

  const handleContinueButtonClick = async () => {
    if (JSON.parse(values.isPmwUser)) {
      control.goToStep(PMW_CREDENTIALS_PAGE_ID);
    } else {
      control.goToStep(BENEFIT_CARD_PAGE_ID);
    }
  };

  return (
    <Container control={control}>
      <IsPmwUserForm formFields={formFields} control={control} />
      <InfoPanel
        topText={t("pmwUser.infoPanel.topText")}
        subText={t("pmwUser.infoPanel.subText")}
      />
      <BlueButton
        onClick={handleContinueButtonClick}
        fullWidth={true}
        label={t("landingPage.getStartedButton.label")}
        ariaLabel={t("landingPage.getStartedButton.label")}
        testId="continue-button"
        control={control}
        disabled={!values.isPmwUser}
      />
    </Container>
  );
};

IsPMWUserView.propTypes = {
  control: ControlPropTypes,
  formFields: FormFieldPropTypes,
};
