import Container from "components/MainContainer/Container";
import { Trans, useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import {
  BlackStyledBody,
  GreyStyledBody,
  StyledContainerExistingUser,
} from "../Common.styles";
import { Title } from "components/TextComponents/TextComponents";
import { useEffect, useState } from "react";
import { clearFormValues } from "components/Utils/InMemoryDataStore/InMemoryDataStore";
import { ColorPalette, LaunchIcon } from "empire-ui";
import { Link } from "react-router-dom";
import { getAppConfig } from "services/api";
import { useIsPortalContext } from "components/Hooks/useIsPortalContext";

export const THANK_YOU_SCREEN_EXISTING_USER_PAGE_ID =
  "thankYouScreenExistingUser";

export const ThankYouScreenExistingUser = ({ control }) => {
  const { t } = useTranslation();
  const isPortal = useIsPortalContext();
  const [urls, setUrls] = useState({});
  const { appStoreUrl, playStoreUrl } = urls;

  useEffect(() => {
    clearFormValues();
    (async () => {
      const { appStoreUrl, playStoreUrl } = await getAppConfig();
      setUrls({ appStoreUrl, playStoreUrl });
    })();
  }, [setUrls]);

  const customTranslationComponents = {
    portalUrl: <Link onClick={control.redirectToPortalLogin} />,
    playStoreUrl: (
      <Link
        onClick={() => {
          window.location = playStoreUrl;
        }}
      />
    ),
    appStoreUrl: (
      <Link
        onClick={() => {
          window.location = appStoreUrl;
        }}
      />
    ),
  };

  return (
    <Container hideTitle hideSubtitle hideSignIn control={control}>
      <StyledContainerExistingUser>
        <Title title={t("thankyouScreen.title")} />
        <Title title={t("thankyouScreen.existingUser.title")} />
        <GreyStyledBody>
          {isPortal
            ? t("thankyouScreen.existingUser.body")
            : t("thankyouScreen.existingUser.appContext.body")}
        </GreyStyledBody>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <LaunchIcon
            fill={ColorPalette.darkBlue[0]}
            height="55px"
            width="70px"
          />
          <BlackStyledBody style={{ marginLeft: "10px", textAlign: "left" }}>
            <Trans
              i18nKey={
                isPortal
                  ? "thankyouScreen.existingUser.portalLink"
                  : "thankyouScreen.existingUser.appContext.appLink"
              }
              components={customTranslationComponents}
            />
          </BlackStyledBody>
        </div>
        {isPortal && (
          <div style={{ display: "flex", textAlign: "top" }}>
            <LaunchIcon
              fill={ColorPalette.darkBlue[0]}
              height="55px"
              width="70px"
            />
            <BlackStyledBody style={{ marginLeft: "10px", textAlign: "left" }}>
              <Trans
                i18nKey={"thankyouScreen.existingUser.appLink"}
                components={customTranslationComponents}
              />
            </BlackStyledBody>
          </div>
        )}
      </StyledContainerExistingUser>
    </Container>
  );
};

ThankYouScreenExistingUser.propTypes = {
  control: ControlPropTypes,
};
