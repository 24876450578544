import Container from "components/MainContainer/Container";
import { Trans, useTranslation } from "react-i18next";
import { GreyStyledBody, StyledContainerNewUser } from "../Common.styles";
import { Title } from "components/TextComponents/TextComponents";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { useFormikContext } from "formik";
import { StyledAlert } from "components/MainContainer/Container.styles";
import { MaterialIcon } from "empire-ui";

export const THANK_YOU_SCREEN_EMAIL_RECOVERY = "thankYouScreenEmailRecovery";

export const ThankYouScreenEmailRecovery = () => {
  const { t } = useTranslation();
  const { values: { registeredEmail } = {} } = useFormikContext();

  return (
    <Container hideTitle hideSubtitle hideSignIn>
      {!registeredEmail && (
        <StyledAlert
          variant="error"
          icon={<MaterialIcon fontSize="34px">error</MaterialIcon>}
          data-testid="page-alert-container"
        >
          {t("form.errors.general")}
        </StyledAlert>
      )}
      {registeredEmail && (
        <StyledContainerNewUser>
          <Title title={t("thankyouScreen.emailRecovery.title")} />
          <GreyStyledBody>
            <Trans
              i18nKey={"thankyouScreen.emailRecovery.body"}
              values={{ emailVar: registeredEmail }}
              components={{
                email: <a href="#" />,
              }}
            />
          </GreyStyledBody>
        </StyledContainerNewUser>
      )}
    </Container>
  );
};

ThankYouScreenEmailRecovery.propTypes = {
  control: ControlPropTypes,
};
