import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import ResponsiveModal from "../ResponsiveModal";
import { useIsPlatformSupported } from "../../Hooks/useIsPlatformSupported";
import { resendActivationEmail, retreiveEmail } from "../../../services/api";
import { useFormikContext } from "formik";
import { THANK_YOU_SCREEN_PASSWORD_RESET_PAGE_ID } from "../../../Pages/ThankYouScreen/PasswordReset";
import { useIsPortalContext } from "components/Hooks/useIsPortalContext";
import { THANK_YOU_SCREEN_EMAIL_RECOVERY } from "Pages/ThankYouScreen/ThankYouScreenEmailRecovery";

export const PRE_EXISTING_USER_MODAL_ID = "existing-user";

export const PreExistingUserModal = ({
  open,
  setOpen,
  control,
  bodytext = "bodyEmail",
  displayResetPassword = true,
  displayRecoverEmail = false,
}) => {
  const { t } = useTranslation();
  const { isSupported } = useIsPlatformSupported();
  const { sendPasswordReset, isLoading } = usePasswordReset({ control });
  const {
    setFieldValue,
    values: {
      email,
      policyNumber,
      divisionNumber,
      certNumber,
      pmwUsername,
      pmwPassword,
      isPmwUser,
    } = {},
  } = useFormikContext();

  const isPortal = useIsPortalContext();

  const handleSignIn = () => {
    control.setLoading(true);
    control.redirectToAppLogin();
    setOpen(false);
  };

  const handlePasswordReset = (email) => {
    setOpen(false);
    sendPasswordReset(email, isPortal);
  };

  const forgotEmail = async () => {
    setOpen(false);
    control.setLoading(true);
    const response = await retreiveEmail({
      policyNumber,
      divisionNumber,
      certNumber,
      pmwUsername,
      pmwPassword,
      isPmwUser,
    });
    control.setLoading(false);
    if (response?.isRateLimited) {
      control.setAlert("form.errors.rateLimitExceeded");
    } else if (!response?.email) {
      control.setAlert("form.errors.general");
    } else {
      setFieldValue("registeredEmail", response?.email);
      control.goToStep(THANK_YOU_SCREEN_EMAIL_RECOVERY);
    }
  };

  const defaultAction = [
    {
      label: isSupported
        ? t("preExistingUserModal.login")
        : t("form.errors.login.platform"),
      testId: "continue-with-my-email-button",
      onClick: handleSignIn,
      disabled: !isSupported,
    },
  ];
  if (displayResetPassword) {
    defaultAction.push({
      label: t("preExistingUserModal.forgotPassword"),
      testId: "reset-password",
      onClick: () => handlePasswordReset(email),
      disabled: isLoading,
    });
  }

  if (displayRecoverEmail) {
    defaultAction.push({
      label: t("preExistingUserModal.forgotUsername"),
      testId: "username-reminder",
      onClick: () => forgotEmail(),
      disabled: false,
    });
  }

  return (
    <ResponsiveModal
      testId="pre-existing-user-modal"
      setOpen={setOpen}
      open={open}
      title={t("preExistingUserModal.title")}
      description={t(`preExistingUserModal.${bodytext}`)}
      actions={defaultAction}
    />
  );
};

const usePasswordReset = ({ control }) => {
  const [isLoading, setIsLoading] = useState(false);
  const sendPasswordReset = useCallback(
    async (email, isPortal) => {
      if (!isLoading) {
        control.setLoading(true);
        const { isEmailSent, isRateLimited } = await resendActivationEmail(
          email,
          true,
          isPortal
        );
        control.setLoading(false);
        if (isRateLimited) {
          control.setAlert("form.errors.rateLimitExceeded");
        } else if (!isEmailSent) {
          control.setAlert("form.errors.general");
        } else {
          control.goToStep(THANK_YOU_SCREEN_PASSWORD_RESET_PAGE_ID);
        }
      }
    },
    [isLoading, setIsLoading]
  );

  return { isLoading, sendPasswordReset };
};

PreExistingUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  control: ControlPropTypes,
  bodytext: PropTypes.string,
  displayResetPassword: PropTypes.bool,
  displayRecoverEmail: PropTypes.bool,
};
